//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export interface IHyperlinkModel
{
	isCurrent: boolean;
	href: string;
	text: string;
}
export interface IPromotion
{
	id: any;
	name: string;
	description: string;
	discountCode: string;
}
export interface IStore
{
	name: string;
	address: string;
	zipCode: string;
	city: string;
	country: string;
	countryCode: string;
	latitude: number;
	longitude: number;
	storeLocatorPageId: number;
}
export interface IAccountStatusResult
{
	hasUserAccount: boolean;
}
export interface IAddBackInStockAlertRequest
{
	productId: number;
	email: string;
	stormCultureCode: string;
	countryCode: string;
	pricelistSeed: string;
	language: string;
	currencyId: number;
}
export interface IAddCartItemsRequest
{
	items: ICartItem[];
	purchaseType: PurchaseType;
}
export interface IAddComplaintRequest
{
	email: string;
	eanCode: string;
	batchNumber: string;
	manufacturingDate: any;
	expirationDate: any;
	manufacturingTime: any;
	machineNumber: string;
	reason: string;
	description: string;
	country: string;
	firstName: string;
	lastName: string;
	address1: string;
	address2: string;
	postalCode: string;
	city: string;
	phone: string;
	agree: boolean;
	images: any[];
	recaptchaResponse: string;
}
export interface IAddItemsFromCartResult
{
	errorMessage: string;
	success: boolean;
	cart: IShoppingCart;
}
export interface IAddress
{
	postalCode: string;
	city: string;
	line1: string;
	line2: string;
	careOf: string;
}
export interface IAgeVerificationRequest
{
	firstName: string;
	lastName: string;
	address1: string;
	address2: string;
	careOf: string;
	city: string;
	email: string;
	postalCode: string;
	phone: string;
	gender: string;
	dateOfBirth: string;
	createAccount: boolean;
	subscribeToNewsletter: boolean;
	purchaseType: PurchaseType;
}
export interface IAgeVerificationStatus
{
	isVerified: boolean;
	expiresInMs: number;
}
export interface IApplyPromoCodeRequest
{
	code: string;
	purchaseType: PurchaseType;
}
export interface IBankIdVerificationRequest
{
	email: string;
	postalCode: string;
	phone: string;
	socialSecurityNumber?: string;
	isOnSameDevice: boolean;
	countryCode: string;
	subscribeToNewsletter: boolean;
	createAccount: boolean;
	purchaseType: PurchaseType;
	isTestMode: boolean;
}
export interface ICartItem
{
	id: number;
	url: string;
	name: string;
	price: string;
	lineNo: number;
	recommendedPrice: string;
	hasDiscount: boolean;
	quantity: number;
	pricelistId: number;
	partNo: string;
	imageUrl: string;
	itemPrice: string;
	recommendedItemPrice: string;
	pricePerCan: string;
	priceBeforeDiscount: string;
	gtmProperties: IGtmProperties;
	productId: number;
	notices: INotice[];
	inStock: boolean;
	productStatus: number;
}
export interface IContentItem
{
	url: string;
	name: string;
	nameInBreadcrumbs: string;
}
export interface ICustomer
{
	email: string;
	postalCode: string;
	city: string;
	line1: string;
	line2: string;
	careOf: string;
	firstName: string;
	lastName: string;
	dateOfBirth: string;
	isNewCustomer: boolean;
}
export interface IDeliveryMethod
{
	id: number;
	name: string;
	isSelected: boolean;
	description: string;
	imagePath: string;
	price: string;
}
export interface IFilter
{
	displayName: string;
	id: string;
	values: IFilterValue[];
	name: string;
}
export interface IFilterValue
{
	isSelected: boolean;
	value: string;
	id: string;
	displayName: string;
}
export interface IFlavorSummaryItem
{
	name: string;
	color: string;
	percentage: string;
}
export interface IFooter
{
	newsLetter: INewsLetter;
	nicotineWarning?: string;
	mainMenu: ILinkItem[];
	subMenu: ILinkItemGroup[];
	metaLinks: ILinkItem[];
	socialLinks: ISocialLink[];
	companyInfo?: string;
	paymentLogos: string[];
}
export interface IGetAccountStatusRequest
{
	email: string;
}
export interface IGetCartsResult
{
	standardCart: IShoppingCart;
	subscriptionCart: IShoppingCart;
	samplingCart: IShoppingCart;
}
export interface IGetContactIdRequest
{
	email: string;
}
export interface IGetRecipientStatusRequest
{
	email: string;
}
export interface IGtmProperties
{
	id: string;
	name: string;
	price: number;
	brand: string;
	category: string;
	variant: string;
	quantity: number;
}
export interface IHeader
{
	mainMenu: ILinkItem[];
	mainMenuContent: IMenuItem[];
	subMenu: ILinkItemGroup[];
	metaLinks: ILinkItem[];
	myPageUrl?: string;
}
export interface IKlarnaCheckoutForm
{
	content: string;
	orderNo: string;
	cart: IShoppingCart;
	buyerEmail: string;
	isNewCustomer: boolean;
}
export interface ICheckoutGtmProperties
{
	orderNumber: string;
	total: string;
}
export interface ILayoutModel
{
	header?: IHeader;
	footer?: IFooter;
	newsletterModal?: INewsletterModal;
	siteMessages?: ISiteMessage[];
	checkoutSiteMessages: ISiteMessage[];
	langResources: any;
	currentMarket?: IMarket;
	language: string;
	markets: IMarket[];
	requestVerificationToken?: string;
	notFoundPageContentId: number;
	isLoggedIn: boolean;
	isLoggedInAsAdmin: boolean;
	isLoggedInAsImpersonator: boolean;
	isImpersonating: boolean;
	userId: string;
	epiClientResourcePath: string;
	showEditButton: boolean;
	isWrongCountry: boolean;
	ipCountry?: string;
	ageGateImageUrl: string;
	ageGateImageUrlMobile: string;
	ageGateUnder18LinkUrl: string;
	environmentName: string;
	theme: string;
	voyadoScriptSrc: string;
	voyadoContactId?: string;
	siteId: string;
	contentType: string;
	useTakeOverHeader: boolean;
	useCampaignHeader: boolean;
	hasSubscribedToNewsletter: boolean;
}
export interface ILinkItem
{
	text: string;
	href: string;
	target?: string;
	title?: string;
}
export interface ILinkItemGroup
{
	heading?: string;
	links: ILinkItem[];
}
export interface ILoginRequest
{
	email: string;
	password: string;
	rememberMe: boolean;
}
export interface ILoginResult
{
	success: boolean;
	errorMessage: string;
}
export interface IMarket
{
	ageGateText: string;
	country: string;
	countryCode: string;
	enablePromoCodes: boolean;
	disableSubscriptionPromoCodes: boolean;
	enableNewsletter: boolean;
	enablePurchases: boolean;
	samplingEnabled: boolean;
	disableSubscriptions: boolean;
	currencyCode: string;
	cultureCode: string;
	checkoutUrl?: string;
	storeLocatorUrl?: string;
	url: string;
	morphVideoOrder: number;
	cartPaymentLogos: string[];
	requireBankIdVerification: boolean;
	countryId: number;
	marketUsps?: string[];
	createAccountUsps?: string[];
	createAccountPrivacyPolicy?: string;
	languages: string[];
	contactPageUrl?: string;
	myAccountPageUrl?: string;
	trackingPageUrl?: string;
	changeCountryLabel: string;
	popularLinks: ILinkItem[];
	startPageId: number;
	searchResultsPageUrl?: string;
	signInPageUrl?: string;
	createAccountPageUrl?: string;
	forgotPasswordPageUrl?: string;
	enableZendeskChatWidget: boolean;
	addToCartConfirmationDuration: number;
	voyadoTenantId?: string;
	useVoyado: boolean;
	enableSampling: boolean;
	enableGlobalAgeVerification: boolean;
	globalAgeVerificationPageUrl: string;
	productContainerPageUrl: string;
	mainProductListingPage: IContentItem;
	nicotineFreeProductListingPage: IContentItem;
}
export interface IMenuItem
{
	text?: string;
	href?: string;
	children?: ILinkItemGroup[];
}
export interface INewsLetter
{
	byline?: string;
	heading?: string;
	termsAndConditions?: string;
	buttonText?: string;
	successHeading?: string;
	successMessage?: string;
}
export interface INewsletterModal
{
	heading: string;
	mainBody: string;
	backgroundColor?: string;
	isActive: boolean;
	termsAndConditions: string;
	clicksToTrigger: number;
	backgroundImage?: string;
	afterSignUpMessage: string;
}
export interface INotice
{
	name: string;
	description: string;
}
export interface IOrder
{
	orderDate: any;
	status: string;
	statusCode: string;
	orderNumber: string;
	delivery: string;
	deliveryMethod: string;
	deliveryAddress: IAddress;
	totalSum: string;
	vat: string;
	items: ICartItem[];
	tracking: ITracking;
}
export interface IPagedList<T>
{
	items: T[];
	pageCount: number;
	totalItemCount: number;
	pageNumber: number;
	pageSize: number;
	hasPreviousPage: boolean;
	hasNextPage: boolean;
	isFirstPage: boolean;
	isLastPage: boolean;
}
export interface IPostalCodeValidationRequest
{
	countryCode: string;
	postalCode: string;
}
export interface IPostalCodeValidationResult
{
	isValid: boolean;
}
export interface IProduct
{
	id: number;
	name: string;
	description: string;
	flags: IProductFlag[];
	variants: IProductVariant[];
	notices: INotice[];
	ean?: string;
	partNo?: string;
	images: string[];
	media: IProductMedia[];
	pouchImage?: IProductMedia;
	ingredientsImageUrl?: string;
	ingredients: string;
	brandUrl: string;
	facts: IProductParametric[];
	howToUseVideoUrl?: string;
	properties: IProductParametric[];
	howToUseVideo?: IProductMedia;
	isSubscribable: boolean;
	isNicotineFree: boolean;
	flavorProfile: IProductParametric[];
}
export interface IProductFilter
{
	name: string;
	color: string;
}
export interface IProductFlag
{
	name: string;
	color: string;
	code: string;
	isCampaignFlag: boolean;
	flagId: number;
	urlSegment: string;
}
export interface IProductItem
{
	id: number;
	tags: string[];
	name: string;
	description: string;
	imageUrl: string;
	url?: string;
	flags: IProductFlag[];
	popularityRank: number;
	ean?: string;
	variants: IProductVariant[];
	standardProductVariant: number;
	strength?: string;
	format?: string;
	showPouchImage: boolean;
	showCanShadow: boolean;
	isPreferredVariant: boolean;
	pouchImageUrl?: string;
	purchaseType: PurchaseType;
}
export interface IProductListResult
{
	items: IProductItem[];
	filters: IProductFilter[];
}
export interface IProductItemsResult
{
	items: IProductItem[];
	filters: IFilter[];
	totalItemCount: number;
	hasNextPage: boolean;
	page: number;
	pageSize: number;
}
export interface IProductMedia
{
	url: string;
	fileType: FileType;
	code: string;
}
export interface IProductParametric
{
	name: string;
	value: string;
	code: string;
}
export interface IProductRelations
{
	relationTypeCode: string;
	productItems: IProductItem[];
}
export enum ProductRelationType {
	UpSell = 258,
	CrossSell = 282,
	StandardAccessory = 340
}
export enum ProductType {
	Standard = 1,
	CampaignProduct = 2,
	Freight = 3,
	Insurance = 4,
	GiftCertificate = 5,
	Download = 7,
	Structure = 8,
	Invoice = 9,
	Demo = 10,
	Service = 11,
	Installation = 12,
	Standard2 = 14,
	ErpExtended = 15,
	ErpStandardNoStock = 16,
	ErpPackage = 17,
	Donations = 18,
	Brand = 19,
	EnvironmentalFee = 20,
	PackingFee = 21,
	Configuration = 22,
	CustomBlend = 29,
	ManagedStructure = 37,
	ManagedErpPPackage = 38,
	ManagedErpPackageWithCalc = 39
}
export interface IProductVariant
{
	id: number;
	partNo: string;
	recommendedPrice: string;
	price: string;
	pricePerCan: string;
	pricelistId: number;
	hasDiscount: boolean;
	inStock: boolean;
	lowInStock: boolean;
	packageSize: number;
	imageUrl?: string;
	productType: ProductType;
	currencyCode: string;
	priceWithoutCurrency: string;
	gtmProperties: IGtmProperties;
	name: string;
	statusId: number;
	isSubscribable: boolean;
	isPreferredVariant: boolean;
}
export interface IPromotionItem
{
	name: string;
	hasCode: boolean;
	appliedAmount?: string;
	code: string;
	activated: boolean;
}
export enum PurchaseType {
	Standard = "Standard",
	Subscription = "Subscription",
	Sampling = "Sampling"
}
export interface IQuickSelectionProduct
{
	id?: number;
	url: string;
	name: string;
	imageUrl: string;
}
export interface IRecipientStatusResult
{
	hasSubscribedToNewsletter: boolean;
}
export interface IRedirectsResult
{
	shouldRedirect: boolean;
	newUrl: string;
	permanent: boolean;
}
export interface IRegisterUserRequest
{
	email: string;
	firstName: string;
	lastName: string;
	password: string;
	signupForNewsletter: boolean;
	countryCode: string;
	address1: string;
	address2: string;
	careOf: string;
	city: string;
	postalCode: string;
	phone: string;
	gender: string;
	dateOfBirth: string;
	invitationCode: string;
}
export interface IRemovePromoCodeRequest
{
	code: string;
	purchaseType: PurchaseType;
}
export interface IResetPasswordRequest
{
	email: string;
}
export enum SamplingInterval {
	Weekly = 0,
	Monthly = 1,
	Yearly = 2
}
export interface ISearchResults
{
	totalHits: number;
	currentPage: number;
	totalPages: number;
	products: IProductItem[];
	content: IContentItem[];
	searchResultUrl: string;
	filters: IFilter[];
}
export interface ISetCreateAccountStatus
{
	createAccount: boolean;
	purchaseType: PurchaseType;
}
export interface ISetNewPasswordRequest
{
	oldPassword: string;
	newPassword: string;
	confirmNewPassword: string;
}
export interface ISetNewsletterSignupStatus
{
	signUp: boolean;
	purchaseType: PurchaseType;
}
export interface ISetPasswordRequest
{
	password: string;
	token: string;
}
export interface ISetPasswordResult
{
	success: boolean;
	errorMessage: string;
}
export interface ISetPostalCodeRequest
{
	postalCode: string;
	purchaseType: PurchaseType;
}
export interface ISetBuyerRequest
{
	postalCode: string;
	email: string;
	gender: string;
	purchaseType: PurchaseType;
}
export interface ISetSamplingBuyerRequest
{
	firstName: string;
	lastName: string;
	address1: string;
	address2: string;
	careOf: string;
	city: string;
	postalCode: string;
	phone: string;
	gender: string;
	dateOfBirth: string;
	subscribeToNewsletter: boolean;
}
export interface ISetUserDataRequest
{
	user: IUser;
	countryCode: string;
}
export interface ISetUserDataResult
{
	success: boolean;
	errorMessage: string;
}
export interface IShoppingCart
{
	id?: number;
	abandondedCartUrl?: string;
	items: ICartItem[];
	totalSum: string;
	vat: string;
	subTotal: string;
	delivery: string;
	leadTime: string;
	checkoutUrl: string;
	appliedPromotions: IPromotionItem[];
	subscriptionScheduleIntervals: ISubscriptionScheduleInterval[];
	subTotalBeforeDiscount: string;
	purchaseType: PurchaseType;
}
export interface ISiteMessage
{
	mainBody: string;
	backgroundColor: any;
	cutOffDate?: any;
	cutOffTime?: any;
	isCountDownTimer: boolean;
	id: number;
}
export interface ISocialLink
{
	linkUrl: string;
	iconCssClass: string;
}
export interface IStockStatus
{
	variantId: number;
	inStock: boolean;
	lowInStock: boolean;
	safetyStock: number;
	lowInStockSafety: number;
}
export interface IStoresResult
{
	stores: IStore[];
}
export interface ISubmitProductSamplePurchaseRequest
{
	question1: string;
	question2: string;
	question3: string;
	acceptPrivacyPolicy: boolean;
	acceptCreateAccount: boolean;
}
export interface ISubmitPurchaseResult
{
	success: boolean;
	errorMessage: string;
	redirectUrl: string;
}
export enum SubscriptionInterval {
	EveryWeek = 1,
	EveryOtherWeek = 2,
	EveryThreeWeeks = 3,
	EveryFourWeeks = 4
}
export interface ISubscriptionOverview
{
	subscriptionId: number;
	nextDelivery: any;
	items: ISubscriptionOverviewItem[];
	scheduleIntervals: ISubscriptionScheduleInterval[];
	deliveryMethods: IDeliveryMethod[];
	selectedDeliveryMethodName: string;
	paymentMethod: string;
	shipping: string;
	subTotal: string;
	vat: string;
	totalAmount: string;
	priceSuffix: string;
	pricePrefix: string;
	status: SubscriptionStatus;
	popularProducts: ISubscriptionOverviewItem[];
	errorMessage: string;
	deliveryAddress: IAddress;
}
export interface ISubscriptionOverviewItem
{
	partNo: string;
	productName: string;
	quantity: number;
	recommendedPrice: string;
	price: string;
	pricePerCan: string;
	priceDisplayWithoutCurrency: number;
	totalSum: string;
	priceOriginalWithoutCurrency: number;
	onHand: number;
	imageKey: any;
	imageUrl: string;
	priceListId: number;
	deliveryAddress: IAddress;
	totalSumBeforeDiscount: string;
}
export interface ISubscriptionScheduleInterval
{
	isSelected: boolean;
	value: SubscriptionInterval;
	name: string;
}
export enum SubscriptionStatus {
	Inactive = 0,
	Active = 1,
	Paused = 2,
	Deleted = 3
}
export interface ITrackCartRequest
{
	purchaseType: PurchaseType;
}
export interface ITrackPurchaseGeneratedRequest
{
	email: string;
	countryCode: string;
	language: string;
	basketId: string;
	totalAmount: string;
	currencyCode: string;
}
export interface IUpdateCartItemRequest
{
	item: ICartItem;
	purchaseType: PurchaseType;
	toBasketItem() : any;
}
export interface IUpdateSubscriptionRequest
{
	subscriptionId: number;
	subscriptionOverViewItemList: ISubscriptionOverviewItem[];
	newProducts: ISubscriptionOverviewItem[];
	deliveryMethodId: number;
	availabilityToken: string;
	deliveryFrequency: SubscriptionInterval;
	status: SubscriptionStatus;
	deliveryAddress: IAddress;
	getBasketItemList() : any[];
}
export interface IUser
{
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	newsletter: boolean;
	invoiceAddress: IAddress;
	deliveryAddress: IAddress;
	useInvoiceAddressAsDeliveryAddress: boolean;
}
export interface IValidationErrorResult
{
	message: string;
	errors?: { [key:string]: string[] };
}
export interface IVerifyPasswordTokenRequest
{
	token: string;
}
export interface IVerifyPasswordTokenResult
{
	success: boolean;
	errorMessage: string;
}
export interface IZendeskProduct
{
	name: string;
}
export interface IZynUsageSummary
{
	favoriteProducts: IProductItem[];
	monthlyUsageGoal: number;
	cansThisYear: number;
	change: string;
	cansByYear: { [key:number]: any[] };
	favoriteFlavorsOneMonth: IFlavorSummaryItem[];
	favoriteFlavorsSixMonths: IFlavorSummaryItem[];
	favoriteFlavorsTwelveMonths: IFlavorSummaryItem[];
}
export interface ITracking
{
	externalId: string;
	trackingNumber: string;
	authToken: string;
	siteId: string;
	userEmail: string;
	url: string;
	locale: string;
	orderEmail: string;
	productUrl: string;
}
export enum FileType {
	Jpg = "Jpg",
	Gif = "Gif",
	Pdf = "Pdf",
	Mp4 = "Mp4",
	Embedded = "Embedded",
	Swf = "Swf",
	Png = "Png",
	Tif = "Tif",
	Eps = "Eps"
}
