<template>
  <div>
    <NuxtLayout>
      <div
        style="background: repeating-linear-gradient(to right, #b0b0b0 0%, rgb(10, 10, 10) 100%); transform-origin: left center; z-index: 999999;"
        class="nuxt-loading-indicator transform fixed top-0 right-0 left-0 pointer-events-none w-auto h-[3px] opacity-0"
        :class="{
          'opacity-100 scale-x-100': pageLoading
        }"
      />
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup>
import { useApplicationInsights } from '~/store/applicationInsights';
import { useGlobalContentStore } from '~/store/globalContent';
import { storeToRefs } from 'pinia';
const appInsights = useApplicationInsights();
const globalContent = useGlobalContentStore();
const runTimeConfig = useRuntimeConfig();
const { requestVerificationToken } = storeToRefs(globalContent);
const config = useGlobalContentStore().config;

onBeforeMount(async()=> {
  // Fetch and set antiforgeryToken
  const fetchAntiForgeryToken = async() => {
    const result = await $fetch(`${runTimeConfig.public.apiUrl}website/xsrf/token`);
    requestVerificationToken.value = result;
  };

  await fetchAntiForgeryToken();
  appInsights.initialize();
});

// Add custom loading indicator when page is loading
const nuxtApp = useNuxtApp();
const pageLoading = ref(false);
const pageLoadingFinish = ref(false);
nuxtApp.hook("page:start", () => {
  pageLoadingFinish.value = false;
  setTimeout(() => {
    if (!pageLoadingFinish.value) {
      pageLoading.value = true;
    }
  }, 200);
});
nuxtApp.hook('page:finish', () => {
  pageLoading.value = false;
  pageLoadingFinish.value = true;
});

const dayjs = useDayjs();

// Set global locale dayjs
const locales = {
  de: async () => await import('dayjs/locale/de'),
  en: async () => await import('dayjs/locale/en'),
  sv: async () => await import('dayjs/locale/sv'),
  da: async () => await import('dayjs/locale/da'),
}

const loadLocale = (dayjs, language) => {
  if (locales[language]) {
    locales[language]().then(() => dayjs.locale(language));
  }
}
loadLocale(dayjs, config.language);

</script>
<style>
.nuxt-loading-indicator {
  transition: all 1s ease;
}

.page-enter-active,
.page-leave-active {
  transition: all 0.15s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}
</style>